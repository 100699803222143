import { Doctor } from "../doctor/doctor.interface";
import { ProductCategory } from "../product-category/product-category.interface";
import { Product } from "../product/product.interface";
import { JobOrderStatus } from "./joborder-status.interface";
import { JobOrderLine } from "./joborderline.interface";

export interface JobOrder {
    id: number;
    job_no:string;
    doc_prefix_id:number;
    job_date: Date;
    patient_name: string;
    enclosed_with_case: string;
    job_units: string;
    enclosed_case_desc: string;
    job_units_desc: string;
    restoration_type: string;
    left_top_tooth_selected: string;
    left_bottom_tooth_selected: string;
    right_top_tooth_selected: string;
    right_bottom_tooth_selected: string;
    shade_name: string;
    instructions: string;
    remarks_section: string;
    delivery_date: Date;
    scheduled_date: Date,
    done_date: Date;
    dispatch_date: Date;
    bill_date: Date;
    status: string;
    doctor: Doctor;
    doctor_id: number;
    doctor_name: string;
    products: Product[];
    product_id: number;
    category: ProductCategory;
    category_id: number;
    category_name: string;
    product_name: string;
    active: boolean;
    next_process: JobOrderStatus;
    current_process: JobOrderStatus;
    product_amt: number;
    comments_description: string;
    product_units: number;
    amount_total: number;
    filename: string;
    procedure: string;
    job_type: string;
    is_dispatched:boolean;
    parent_id:number;
    order_lines: JobOrderLine[];
    parent : JobOrder;
};

export var displayColumns = [
    'job_type',
    'procedure',
    'job_no',   
    'job_date',
    'doctor_name',
    'patient_name',
    'category_name',
    'delivery_date',
    'status'
]

export var displayHeaders = [
    'Job Type',
    'Procedure',
    'Job No.',   
    'Job Date',
    'Doctor Name',
    'Paitient Name',
    'Product Category',
    'Delivery Date',
    'Status'
]

// actual database columns
export var orderByColumns = [
    'job_type',
    'procedure',
    'job_no',
    'job_date',
    'doctor_name',
    'patient_name',
    'category_name',
    'delivery_date',
    'status'
]

export const filterParameters = [
    { 
        name: 'job_date', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Date Range' 
    },
    { 
        name: 'doctor_id', 
        type: 'dropdown', 
        operator: '=',
        model_name:'doctor',
        label: 'Doctor' 
    },
    { 
        name: 'patient_name', 
        type: 'string', 
        operator: 'like', 
        label: 'Patient Name'
    },
    { 
        name: 'category_id', 
        type: 'dropdown', 
        operator: '=',
        model_name:'category',
        label: 'Product Category' 
    },
    { 
        name: 'job_type', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Regular', value: "regular" },
                    { label: 'Repair', value: "repair" },
                    { label: 'Repeat', value: "repeat" }
                ],
        model_name:'joborder',
        label: 'Job Type' 
    },
    { 
        name: 'procedure', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Digital', value: "digital" },
                    { label: 'Physical', value: "physical" }
                ],
        model_name:'joborder',
        label: 'Procedure' 
    },
    { 
        name: 'job_no', 
        type: 'string', 
        operator: 'like', 
        label: 'Job No.', 
    },
    { 
        name: 'status', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Draft', value: "draft" },
                    { label: 'In Production', value: "in_production" },
                    { label: 'Done', value: "done" },
                    { label: 'Hold', value: "hold" },
                    { label: 'Canceled', value: "canceled" }
                ],
        model_name:'joborder',
        label: 'Status' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'joborder',
        label: 'Select columns for order by' 
    }
]  


export const schedulefilterParameters = [
    { 
        name: 'job_date', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Date Range' 
    },
    { 
        name: 'doctor_id', 
        type: 'dropdown', 
        operator: '=',
        model_name:'doctor',
        label: 'Doctor' 
    },
    { 
        name: 'patient_name', 
        type: 'string', 
        operator: 'like', 
        label: 'Patient Name'
    },
    { 
        name: 'category_id', 
        type: 'dropdown', 
        operator: '=',
        model_name:'category',
        label: 'Product Category' 
    },
    { 
        name: 'job_type', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Regular', value: "regular" },
                    { label: 'Repair', value: "repair" },
                    { label: 'Repeat', value: "repeat" }
                ],
        model_name:'joborder',
        label: 'Job Type'
    },
    { 
        name: 'procedure', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Digital', value: "digital" },
                    { label: 'Physical', value: "physical" }
                ],
        model_name:'joborder',
        label: 'Procedure' 
    },
    { 
        name: 'status', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Draft', value: "draft" },
                    { label: 'In Production', value: "in_production" },
                    { label: 'Done', value: "done" },
                    { label: 'Hold', value: "hold" },
                    { label: 'Canceled', value: "canceled" }
                ],
        model_name:'joborder',
        label: 'Status' 
    },
    { 
        name: 'job_no', 
        type: 'string', 
        operator: 'like', 
        label: 'Job No.', 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'joborder',
        label: 'Select columns for order by' 
    }
]  
