import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Shipment } from './shipment.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';


@Component({
    selector: 'app-shipment.form',
    templateUrl: './shipment.form.component.html',
    styleUrls: ['./shipment.form.component.css']
})
export class ShipmentFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }

    public model = 'shipment'
    public shipment = {} as Shipment;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    ngOnInit() {
        let shipmentId = this.route.snapshot.params.id
        if (shipmentId === 'create') {
            console.log('False')
            this.isFormDisabled = false;
        }
        else {
            this.getShipment(Number(shipmentId));
        }
    }

    getShipment(shipmentId: number) {
        this.api.get(this.model, shipmentId).subscribe(
            (data: any) => {
                if (data) {
                    this.shipment = data;
                }
            }
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.onSaveDisable = true;
        this.api.createUpdate(this.model, this.shipment).subscribe(
            (data: any) => {
                if (data) {
                    this.shipment = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.router.navigate([`/${this.model}/${data.id}`]);
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.shipment.id){
                    this.isFormDisabled = true;
                    this.getShipment(this.shipment.id);
                }else{
                    this.router.navigate([`/shipment`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('shipment/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
