<form #priceListCheckForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/pricelist']" routerLinkActive="router-link-active">Price List</a> >
                {{pricelist.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(priceListCheckForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">List Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="pricelist.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">
                        List name required
                    </div>
                </div>
            </div>
        </fieldset>

        <div>
            <div *ngIf="showTable" class="row p-4">
                <mat-form-field class="col-5">
                    <input matInput type="text" (keyup)="doFilter($event)" placeholder="Search Products">
                </mat-form-field>
            </div>
            <fieldset [disabled]="isFormDisabled">
                <table *ngIf="showTable" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">

                    <ng-container  matColumnDef="name">
                        <th class="col-8" mat-header-cell *matHeaderCellDef>Product Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.product_name}} </td>
                    </ng-container>
                    <ng-container  matColumnDef="rate">
                        <th class="col-4" mat-header-cell *matHeaderCellDef>Rate</th>
                        <td mat-cell *matCellDef="let element; let index = index">
                            <input name="rate-{{index}}" [(ngModel)]="element.rate" (keypress)="onlyNumber($event)" (focusout)="onFocusOut(element.product.id, element.rate)" type="text">
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </fieldset>
        </div>
    </div>
</form>