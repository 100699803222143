<form #salesReturnForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-7 form-breadcrumb">
            <h3><a [routerLink]="['/sales_return']" routerLinkActive="router-link-active">Sales Return</a> > {{sales_return.sales_return_no}} </h3>
        </div>
        <div class="col-sm-1 form-operation">
            <a *ngIf="sales_return.id && isFormDisabled && !['cancelled'].includes(sales_return.status)" target="_blank" href="/api/sales_return/{{sales_return.id}}/download">
                <button class="m-2" type="button" mat-raised-button color="primary">Print</button>
            </a>
        </div>
        <div class="col-sm-4 form-operation" *ngIf="!['submitted','cancelled'].includes(sales_return.status)">
            <button *ngIf="isFormDisabled && sales_return.status == 'draft'" class="m-2" type="button" mat-raised-button color="primary" (click)='confirmDialog()'>Submit</button>
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(salesReturnForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled && sales_return.status == 'draft'" class="m-2" type="button" mat-raised-button color="link" (click)="cancelConfirmDialog()">Cancel</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="sales_return_no" class="col-sm-2 col-form-label">Sales Return No</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #sales_return_no="ngModel" [disabled]="true" type="text" matInput name="sales_return_no" [(ngModel)]="sales_return.sales_return_no">
                    </mat-form-field>
                    <div class="validation-class" *ngIf="sales_return_no.touched && sales_return_no.invalid">Required</div>
                </div>

                <label for="sales_return_date" class="col-sm-2 col-form-label">Sales Return Date <span class="span-class"> *</span></label>
                <div class="col-sm-4 dateRangePickStyle">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input #date="ngModel" matInput [(ngModel)]="sales_return.sales_return_date" name="job_date" [matDatepicker]="sales_return_date" [min]="minDate" [max]="maxDate" required>
                        <mat-datepicker-toggle matSuffix [for]="sales_return_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #sales_return_date></mat-datepicker>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
                </div>
            </div>
            <div class="row pb-4">

                <label for="sales_ret_type" class="col-sm-2 col-form-label">Type<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [(value)]="selectedSalesType" [disabled]="isFormDisabled || disableDoctorSelection" required autocomplete="off" (selectionChange)="onSalesTypeChange()">
                            <mat-option *ngFor="let sales_ret_type of sales_ret_type" [value]="sales_ret_type.value">
                                {{sales_ret_type.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="row pb-4">

                <label for="company" class="col-sm-2 col-form-label">Company<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedCompany" (selectionChange)="onCompanyChange()">
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onCompanyKey($event)" matInput name="company_list">
                            <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">{{company.company_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)" [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDoctor" (selectionChange)="onDoctorChange()" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput name="doctort_list">
                            <mat-option *ngFor="let doctor of allDoctors" [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pb-4">

                <label for="select_invoice" class="col-sm-2 col-form-label">Select Invoice<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list class="matChipClasscross" #chipList [multiple]="false" aria-label="Invoice selection">
                            <mat-chip class="matChipClass" *ngFor="let invoice of selectedInvoiceChips" [selectable]="selectable" [removable]="removable" (removed)="remove(invoice)">
                                {{invoice}}
                                <button matChipRemove *ngIf="removable">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input #chipInput onkeydown="return false;" [formControl]="invoiceCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let invoice of notReturnedInvoices" [value]="invoice">
                                {{invoice.invoice_no}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <label for="account" class="col-sm-2 col-form-label">Account <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #account_id (openedChange)="onAccountScroll($event)" [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedAccount" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onAccountKey($event)" matInput name="account_list">
                            <mat-option *ngFor="let account of allAccounts" [value]="account.id">{{account.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>


            <table mat-table matSort [dataSource]="dataSource" #sales_lines class="mat-elevation-z2">
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let i = index" style="cursor:pointer;" (click)="deleteLine(i)"><i class="material-icons">delete</i></td>
                </ng-container>
                <ng-container matColumnDef="job_no">
                    <th mat-header-cell *matHeaderCellDef>Job No.</th>
                    <td mat-cell *matCellDef="let element"> {{element.job_no}} </td>
                </ng-container>

                <ng-container matColumnDef="product_name">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.product_name}} </td>
                </ng-container>

                <ng-container matColumnDef="product_units">
                    <th mat-header-cell *matHeaderCellDef>Unit</th>
                    <td mat-cell *matCellDef="let element"> {{element.product_units}} </td>
                </ng-container>

                <ng-container matColumnDef="rate">
                    <th mat-header-cell *matHeaderCellDef>Sales Rate</th>
                    <td mat-cell *matCellDef="let element"> {{element.rate | currency :'INR':'symbol':'1.2-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="change_rate">
                    <th mat-header-cell *matHeaderCellDef>Change Rate</th>
                    <td mat-cell *matCellDef="let element;  let index = index">
                        <input name="change_rate-{{index}}" [(ngModel)]="element.change_rate" (focusout)="calculateTaxesAndTotals(sales_return)" type="number" max="{{element.rate}}">
                    </td>
                </ng-container>

                <ng-container matColumnDef="discount_percent">
                    <th mat-header-cell *matHeaderCellDef>Discount (%)</th>
                    <td mat-cell *matCellDef="let element"> {{element.discount_percent}} </td>
                </ng-container>

                <ng-container matColumnDef="amount_total">
                    <th class="text-right" mat-header-cell *matHeaderCellDef>Amount</th>
                    <td class="text-right" mat-cell *matCellDef="let element "> {{element.amount_total | currency :'INR':'symbol':'1.2-2'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

            </table>

            <div class="row pt-4">

                <div class="col-sm-6">

                </div>

                <div class="col-sm-6">
                    <div class="mat-table">
                        <div class="mat-header-row">
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>Sub Total (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.total_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>Discount (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.discount_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>SGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.sgst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>CGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.cgst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>IGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.igst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>Grand Total (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.grand_total | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div *ngIf="selectedSalesType == 'change_rate'" class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>Change Rate Difference (Incl. Tax) (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{sales_return.change_amount_total | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </fieldset>
    </div>
</form>