import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { CommonApiService } from "../../common-api.service";
import { Challan, displayColumns, displayHeaders, filterParameters } from './challan.interface';
import { DatePipe } from '@angular/common';
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challan',
  templateUrl: './challan.component.html',
  styleUrls: ['./challan.component.css']
})
export class ChallanComponent implements OnInit {
  
	displayColumns = displayColumns;
	displayHeaders = displayHeaders;
	challan: Challan[] = [];
	filterFields: any[] =[]
    filterCriteria: any[] =[]
    doctorsOptions:any[] = [];
    companyOptions:any[] = [];

	@ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private eventEmitterService: EventEmitterService,
		private datePipe: DatePipe,
		private dialog: MatDialog,
        private router: Router,
        private renderFilterDropdown: RenderFilterDropdownService
	) {}

	// common code above this line for all components
	transformDate(date: any) {
		date = new Date(date);
		var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
		return converteddate;
	}

	ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad 

        this.getListDetails();
		this.speaker.model = 'challan';
		this.speaker.displayColumns = this.displayColumns;
		this.speaker.displayHeaders = this.displayHeaders;
		this.speaker.listData = this.challan;

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });
		this.onDropdownRenderOptions('doctor/list/display', this.doctorsOptions);
        this.onDropdownRenderOptions('company/list/display', this.companyOptions);


    }

	// dynamic func for dropdown loading in filters
	onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

	getListDetails() {
		let params = {
            pageIndex: this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex : environment.defaultPageIndexOnLoad,
            pageSize: this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize : environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
		};

        this.api.postAll('challan/list/display', params).subscribe((res: any) => {

			this.challan = res.items
			this.challan.forEach((element) => {

				if(element.challan_date){
					element.challan_date = this.transformDate(element.challan_date)
				}
                if (element.job_type == "repair") {
                    element.job_type = "Repair";
                } else if(element.job_type == "regular") {
                    element.job_type = "Regular";
                } else if (element.job_type == "repeat") {
                    element.job_type = "Repeat";
                }
				if(element.doctor){
					element.doctor_name = element.doctor.name;
				}
                if(element.doctor.company){
					element.company_name = element.doctor.company.company_name;
				}
                element.is_proforma_invoiced = element.is_proforma_invoiced ? "Yes" : "No";
				element.status = element.status.toUpperCase()
			});
		
			// Set the transformed response to the speaker service
            this.speaker.setListData(this.challan, res.rowCount, res.pageIndex, res.pageSize, res.allIds);
		});
	}

	// set options for dropdown filters
    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
          doctor: this.doctorsOptions,
          company: this.companyOptions,
          // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

	openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.filterCriteria = []
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}

