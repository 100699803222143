<form #jobForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-7 form-breadcrumb">
            <h3><a [routerLink]="['/joborder']" routerLinkActive="router-link-active">Job Orders</a> > {{joborder.job_no}} </h3>
        </div>
        <!-- <div class="col-sm-1 form-operation">
            <a *ngIf="joborder.id && isFormDisabled" target="_blank" href="/api/joborder/{{joborder.id}}/download">
                <button type="button" mat-raised-button color="primary">Print</button>
            </a>
        </div> -->
        <div class=" col-sm-5 form-operation">
            <div class="col-sm-6 form-operation" *ngIf="!['done','in_production'].includes(joborder.status)">
                <!-- <button *ngIf="['draft'].includes(joborder.status) && isFormDisabled" class="mx-3" type="button" mat-raised-button color="primary" (click)="confirmDialog(joborder.id)">Schedule</button> -->
                <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" type="submit" class="m-2" mat-raised-button color="primary" (click)='save(jobForm.valid)'>Save</button>
                <button *ngIf="isFormDisabled && !['canceled','hold'].includes(joborder.status)" type="button" class="m-2" mat-raised-button color="link" (click)='edit()'>Edit</button>
                <button *ngIf="isFormDisabled" type="button" class="m-2" mat-raised-button color="primary" (click)='create()'>Create</button>
                <button *ngIf="!isFormDisabled" type="button" class="m-2" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>

            </div>
            <div class="col-sm-2 form-operation">
                <button *ngIf="!['canceled'].includes(joborder.status)" class="m-2" [disabled]="!(joborder.id && isFormDisabled)" mat-raised-button color="link" [matMenuTriggerFor]="menu">Action</button>

                <mat-menu #menu="matMenu" title="ACTION">
                    <button [disabled]="!['in_production'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('draft')">Save as Draft</button>
                    <button [disabled]="!['draft'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('schedule')">Schedule order</button>
                    <button [disabled]="!['in_production'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('hold')">Hold order</button>
                    <button [disabled]="!['hold'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('release')">Release order</button>
                    <button [disabled]="joborder.is_dispatched" mat-menu-item (click)="confirmDialog('cancel')">Cancel order</button>
                    <button [disabled]="!['done'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('repeat')">Create Repeat order</button>
                    <button [disabled]="!['done'].includes(joborder.status)" mat-menu-item (click)="confirmDialog('repair')">Create Repair order</button>
                    <button mat-menu-item (click)="printJobOrder()">Print</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div *ngIf="joborder.parent_id" class="row">
                <label for="parent_id" class="col-sm-2 col-form-label">Parent Job No.</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input [disabled]="true" type="text" matInput name="parent_id" #parent_id="ngModel" [(ngModel)]="joborder.parent.job_no">
                    </mat-form-field>
                </div>
            </div>

            <div class="row pb-4">
                <label for="type" class="col-sm-2 col-form-label">Job Type <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="true" [(value)]="selectedJobType" required autocomplete="off">
                            <mat-option *ngFor="let jobtype of job_type" [value]="jobtype.value">
                                {{jobtype.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="type" class="col-sm-2 col-form-label">Procedure <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedProcedure" required autocomplete="off">
                            <mat-option *ngFor="let procedure of procedure" [value]="procedure.value">
                                {{procedure.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label *ngIf="joborder.job_no" for="job_no" class="col-sm-2 col-form-label">Job No. <span class="span-class"> *</span></label>
                <div *ngIf="joborder.job_no" class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input [disabled]="true" type="text" matInput name="job_no" required [(ngModel)]="joborder.job_no">
                    </mat-form-field>
                    <!-- <div class="validation-class" *ngIf="job_no.touched && job_no.invalid">Required</div>
                    <div class="validation-class" *ngIf="dupicateJob">Duplicate job number!</div> -->
                </div>

                <label *ngIf="!joborder.job_no" for="doc_prefix_id" class="col-sm-2 col-form-label">Job Prefix</label>
                <div *ngIf="!joborder.job_no" class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [(value)]="selectedJobPrefix" autocomplete="off" #doc_prefix_id [disabled]="isFormDisabled">
                            <mat-option *ngFor="let prefix of allJobPrefix" [value]="prefix.id">
                                {{prefix.prefix}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <label for="job_date" class="col-sm-2 col-form-label">Job Date <span class="span-class"> *</span></label>
                <div class="col-sm-4 dateRangePickStyle">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input #date="ngModel" [disabled]="isFormDisabled" matInput [min]="minDate" matInput [(ngModel)]="joborder.job_date" name="job_date" [matDatepicker]="job_date" [min]="minDate" [max]="maxDate" required>
                        <mat-datepicker-toggle matSuffix [for]="job_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #job_date></mat-datepicker>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
                </div>
            </div>
            <!-- key down added so that dropdown search can accept space in between search values
                to be done for all the dropdowns    
            -->
            <!-- for lazy loading in dropdown we have to define 
                - onOpenedChange
                - "#doctor_id" to mat select
                - keyup
            -->
            <div class="row">
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)" [disabled]="isFormDisabled" [(value)]="selectedDoctor" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput name="doctort_list">
                            <mat-option *ngFor="let doctor of allDoctors" [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <label for="patient_name" class="col-sm-2 col-form-label">Patient Name</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #state="ngModel" type="text" matInput name="patient_name" [(ngModel)]="joborder.patient_name">
                    </mat-form-field>
                </div>
            </div>

            <div class="row pt-4">
                <label for="enclosed_with_case" class="col-sm-2 col-form-label">Enclosed With Case</label>
                <div class="col-sm-6">
                    <section id="enclosed_with_case" class="example-section">
                        <mat-checkbox class="example-margin" color="primary" (change)="enclosedCaseChkBox($event)" [checked]="enclosed_cases_selected?.includes(case)" *ngFor="let case of enclosed_cases" type="checkbox" value={{case}} id={{case}} name="num2">{{case}}</mat-checkbox>
                    </section>
                </div>
                

                <div class="col-sm-3">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" placeholder="If Others then specify" matInput name="enclosed_case_desc" [(ngModel)]="joborder.enclosed_case_desc">
                    </mat-form-field>
                </div>
            </div>
            <div class="row pt-4">
                
                <label for="job_units" class="col-sm-2 col-form-label">Units</label>
                <div class="col-sm-6">

                    <section id="job_units" class="example-section">
                        <mat-checkbox class="example-margin" color="primary" (change)="unitsCaseChkBox($event)" [checked]="job_units_selected?.includes(case)" *ngFor="let case of unit_cases" type="checkbox" value={{case}} id={{case}} name="num2">{{case}}</mat-checkbox>
                    </section>
                </div>

                <div class="col-sm-3">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" placeholder="If Others Units then specify" matInput name="job_units_desc" [(ngModel)]="joborder.job_units_desc">
                    </mat-form-field>
                </div>
            </div>

            <div class="row pt-4">
                <label for="restoration_type" class="col-sm-2 col-form-label">Restoration Type</label>
                <div class="col-sm-5">
                    <section class="example-section">
                        <mat-checkbox class="example-margin" color="primary" (change)="restoTypeChkBox($event)" [checked]="restoration_type_selected?.includes(resto_type)" *ngFor="let resto_type of restoration_type" type="checkbox" value={{resto_type}} id={{resto_type}} name="num">{{resto_type}}</mat-checkbox>
                    </section>
                </div>
            </div>

            <div class="row pt-4">
                <label for="category" class="col-sm-2 col-form-label">Category <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl catDropdown">
                        <mat-select #category_id (selectionChange)="onCategoryChange($event)" (openedChange)="onCategoryScroll($event)" [disabled]="isFormDisabled" [(value)]="selectedCategory" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onCategoryKey($event)" matInput name="category_list">
                            <mat-option *ngFor="let category of allCategories" [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
                <label for="shade_name" class="col-sm-2 col-form-label">Shade</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="shade_name" [(ngModel)]="joborder.shade_name">
                    </mat-form-field>
                </div>
            </div>

            <mat-tab-group animationDuration="0ms" class="mt-5">
                <mat-tab id="products" label="Products">
                    <button [disabled]="isFormDisabled" mat-raised-button (click)="openDialog(0)" color="primary" class="example-action-button m-4">
                        Add Product
                    </button>
                    <table mat-table [dataSource]="productLinesSource" #product_lines class="mat-elevation-z8">

                        <ng-container matColumnDef="product_name">
                            <th mat-header-cell *matHeaderCellDef>Product Name</th>
                            <td mat-cell *matCellDef="let element" (click)="openDialog(element)">{{element.product.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element; let i = index" style="cursor:pointer;" (click)="deleteLine(i)"><i class="material-icons">delete</i></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="orderLineDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: orderLineDisplayedColumns;"></tr>
                    </table>
                </mat-tab>
            </mat-tab-group>

            <div class="row pb-4 pt-4">
                <div class="col-sm-6">
                    <label for="instructions" class="col-form-label">Instructions</label>
                    <textarea rows="4" class="textarea-bg" matInput name="instructions" [(ngModel)]="joborder.instructions"></textarea>
                </div>

                <div class="col-sm-6 pt-4">
                    <label for="delivery_date" class="col-sm-4 col-form-label">Delivery Date<span class="span-class"> *</span></label>
                    <div class="col-sm-6 dateRangePickStyle">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <input #deliverydate="ngModel" [disabled]="isFormDisabled" matInput [(ngModel)]="joborder.delivery_date" name="delivery_date" [matDatepicker]="delivery_date" required>
                            <mat-datepicker-toggle matSuffix [for]="delivery_date">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #delivery_date></mat-datepicker>
                        </mat-form-field>
                        <div class="validation-class" *ngIf="deliverydate.touched && deliverydate.invalid">Required</div>
                    </div>
                </div>
            </div>

            <div class="row pt-4">
                <div class="row pb-4">
                    <label for="remarks" class="col-sm-2 col-form-label">Select remarks</label>
                    <div class="col-sm-3">
                        <mat-form-field class="example-chip-list">
                            <mat-chip-list class="matChipClasscross" #chipList aria-label="Remark selection">
                                <mat-chip class="matChipClass" *ngFor="let remark of remarks" [selectable]="selectable" [removable]="removable" (removed)="remove(remark)">
                                    {{remark}}
                                    <button matChipRemove *ngIf="removable">
                                <mat-icon>cancel</mat-icon>
                                </button>
                                </mat-chip>
                                <input #remarkInput [formControl]="remarkCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let remark of filteredRemarks" [value]="remark">
                                    {{remark.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-1">
                    </div>
                    <div class="col-sm-5">
                        <label for="remarks_desc" class="col-form-label">Remarks Description</label>
                        <textarea id="remarks_section" rows="4" class="textarea-bg" [(ngModel)]="joborder.remarks_section" matInput name="remarks_section"></textarea>
                    </div>
                </div>


                <div class="row pb-4" *ngIf="joborder.id">
                    <div class="col-sm-2">
                        <label for="upload" class="col-sm-2 col-form-label">Attachment</label>
                    </div>
                    <div class="col-sm-2" *ngIf="afuConfig">
                        <angular-file-uploader [config]="afuConfig" (ApiResponse)="docUpload($event)">
                        </angular-file-uploader>
                    </div>
                </div>

                <div class="row pb-4" *ngIf="joborder.id">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2" *ngIf="joborder.filename">
                        <a style="font-size: 12px; padding-left: 10px;" target="_blank" href="/src/job_order_uploads/{{joborder.filename}}">{{joborder.filename}}</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <label for="leave_reply" class="col-form-label">Comments</label>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <ul class="commentUl">
                        <li *ngFor="let comments of allComments">
                            <p class="commentSpan col-sm-12"><b>{{comments.employee?.name}}</b> {{ comments.created_at | date :'medium'}}
                                <br><br>{{comments.comment_description}} </p>
                        </li>
                    </ul>
                </div>
            </div>

        </fieldset>
    </div>
</form>