<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p [innerHTML]="message"></p>
</div>

<label *ngIf="this.repairJob" for="process_name" class="col-sm-12 col-form-label">Repair from Process <span class="span-class"> *</span></label>
<div *ngIf="this.repairJob" class="row">
    <div class="col-sm-12">
        <mat-form-field class="MatFormFieldControl">
            <mat-select #process_id [(value)]="selectedProcess" required>
                <mat-option *ngFor="let process of jobProcesses" [value]="process.id">{{process.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div *ngIf="this.showCompanySelection" class="row">
    <div class="col-sm-12">
        <mat-form-field class="MatFormFieldControl">
            <mat-label>Company</mat-label>
            <mat-select #company_selection [(value)]="selectedCompany" required>
                <mat-option *ngFor="let company of allCompanies" [value]="company.id">{{company.company_name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="row" *ngIf="this.hold_or_release">
    <div class="col-sm-12">
        <textarea id="comment_description" placeholder="If yes, specify the reason" rows="2" class="textarea-bg" matInput name="comment_description" [(ngModel)]="comment_description"></textarea>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
</div>